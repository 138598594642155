<template>
  <div>
    <h2 class="faledia-caption">Diagnose & Förderung</h2>
    <p>
      Auch wenn das Hintergrundwissen zum Einspluseins wichtig ist, ist es
      genauso wichtig, dieses Wissen auf konkrete Schüler:innendokumente
      anwenden zu können. Es ist dabei bedeutsam erfassen zu können, in welchen
      Bereichen das Einspluseins bereits gut ausgebildet ist und in welchen
      Bereichen eventuell noch Probleme vorliegen.
    </p>
    <p>
      Die folgende Aktivität bietet Einblicke in drei unterschiedliche Dokumente
      von Kindern. Celine hat ein Plustrio aus drei Karten mit drei
      unterschiedlichen Darstellungen zu der Aufgabe 9 + 3 erstellt. Schauen Sie
      sich das Plustrio von Celine an und bearbeiten Sie die einzelnen Aufgaben
      dazu.
    </p>

    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[0].id"
        :workOrder="LBSTStempeln[0].workOrder"
        :elements="LBSTStempeln[0].elements"
        :image="LBSTStempeln[0].image"
      >
        <template v-slot:alternative-content-0>
          <p>
            Das Plustrio von Celine zeigt, dass sie keine Probleme damit hat die
            Aufgabe 9+3 am Rechenstrich darzustellen. Allerdings nimmt Celine
            bei der Darstellung der Aufgabe 9+3, nicht das Konstanzgesetz der
            Summe zu Hilfe. Es scheint eher so, dass Celine sich das
            Assoziativgesetz zur Hilfe nimmt, in dem sie zunächst zum nächsten
            Zehner rechnet und dann weiter.
          </p>
        </template>
        <template v-slot:alternative-content-1>
          <p>
            Das Plustrio von Sina zeigt, dass sie keinerlei Probleme mit der
            Berechnung einer Additionsaufgabe zu haben scheint, ebenso wie mit
            der Darstellung am Rechenstrich. Probleme scheint sie hingegen bei
            dem Verfassen einer Rechengeschichte zu einer Additionsaufgabe zu
            haben, da ihr dies nicht für die Aufgabe 9+3 gelingt. Sie nutzt hier
            zwar die Zahlen der Rechenaufgabe, setzt diese aber nicht in einen
            sinnvollen Zusammenhang.
          </p>
          <p>
            Eine weitere Diagnoseaufgabe sollte daher am besten bei der
            Problematik mit den Rechengeschichten ansetzen, um mehr über ihre
            Probleme in diesem Bereich zu erfahren.
          </p>
        </template>
        <template v-slot:alternative-content>
          <p>
            Das Plustrio von Celine zeigt, dass sie keine Probleme damit hat die
            Aufgabe 9+3 am Rechenstrich darzustellen. Allerdings nimmt Celine
            bei der Darstellung der Aufgabe 9+3, nicht das Konstanzgesetz der
            Summe zu Hilfe. Es scheint eher so, dass Celine sich das
            Assoziativgesetz zur Hilfe nimmt, in dem sie zunächst zum nächsten
            Zehner rechnet und dann weiter.
          </p>
          <p>
            Das Plustrio von Sina zeigt, dass sie keinerlei Probleme mit der
            Berechnung einer Additionsaufgabe zu haben scheint, ebenso wie mit
            der Darstellung am Rechenstrich. Probleme scheint sie hingegen bei
            dem Verfassen einer Rechengeschichte zu einer Additionsaufgabe zu
            haben, da ihr dies nicht für die Aufgabe 9+3 gelingt. Sie nutzt hier
            zwar die Zahlen der Rechenaufgabe, setzt diese aber nicht in einen
            sinnvollen Zusammenhang.
          </p>
          <p>
            Eine weitere Diagnoseaufgabe sollte daher am besten bei der
            Problematik mit den Rechengeschichten ansetzen, um mehr über ihre
            Probleme in diesem Bereich zu erfahren.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>

    <p>
      Es folgt ein weiteres Plustrio von Ravi, schauen Sie sich auch dieses an
      und bearbeiten Sie die einzelnen Aufgaben.
    </p>

    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[1].id"
        :workOrder="LBSTStempeln[1].workOrder"
        :elements="LBSTStempeln[1].elements"
        :image="LBSTStempeln[1].image"
      >
        <template v-slot:alternative-content-0>
          <p>
            In dem Plustrio von Ravi kann man erkennen, dass Ravi eine
            Grundvorstellung der Addition in der Rechengeschichte zeigt.
            Allerdings ist es nicht die Grundvorstellung „Zusammenfassen“,
            sondern die Grundvorstellung „Hinzufügen“, denn in der
            Rechengeschichte von Ravi, wird einer Menge von Objekten, eine
            weitere Menge hinzugefügt.
          </p>
        </template>
        <template v-slot:alternative-content-1>
          <p>
            Ravi zeigt bei der Berechnung der Aufgabe 7+8, dass er noch
            Schwierigkeiten damit hat Additionsaufgaben richtig zu lösen.
          </p>
          <p>
            Die Bearbeitung am Rechenstrich, sowie das Verfassen einer
            Rechengeschichte, bereiten ihm allerdings keine Probleme. Deshalb
            wäre es nicht hilfreich Diagnoseaufgaben in diese Richtung zu
            stellen, um mehr über Ravis Rechenfehler zu erfahren.
          </p>
          <p>
            Es scheint daher sinnvoller Ravi eine weitere Additionsaufgabe
            rechnen zu lassen und ihn erklären zu lassen, wie er auf sein
            Ergebnis gekommen ist. Das ermöglicht es einen Einblick in Ravis
            Vorgehensweise zu bekommen und zu erkennen, an welcher Stelle seine
            Probleme bei der Berechnung auftreten.
          </p>
        </template>
        <template v-slot:alternative-content>
          <p>
            In dem Plustrio von Ravi kann man erkennen, dass Ravi eine
            Grundvorstellung der Addition in der Rechengeschichte zeigt.
            Allerdings ist es nicht die Grundvorstellung „Zusammenfassen“,
            sondern die Grundvorstellung „Hinzufügen“, denn in der
            Rechengeschichte von Ravi, wird einer Menge von Objekten, eine
            weitere Menge hinzugefügt.
          </p>
          <p>
            Ravi zeigt bei der Berechnung der Aufgabe 7+8, dass er noch
            Schwierigkeiten damit hat Additionsaufgaben richtig zu lösen.
          </p>
          <p>
            Die Bearbeitung am Rechenstrich, sowie das Verfassen einer
            Rechengeschichte, bereiten ihm allerdings keine Probleme. Deshalb
            wäre es nicht hilfreich Diagnoseaufgaben in diese Richtung zu
            stellen, um mehr über Ravis Rechenfehler zu erfahren.
          </p>
          <p>
            Es scheint daher sinnvoller Ravi eine weitere Additionsaufgabe
            rechnen zu lassen und ihn erklären zu lassen, wie er auf sein
            Ergebnis gekommen ist. Das ermöglicht es einen Einblick in Ravis
            Vorgehensweise zu bekommen und zu erkennen, an welcher Stelle seine
            Probleme bei der Berechnung auftreten.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>

    <p>
      Die Unterschiede zwischen den Plustrios von Celine und Ravi sind deutlich
      zu erkennen, auch wenn sie auf den ersten Blick an ähnlichen Stellen
      liegen. Die Bearbeitungen von Celine und Ravi sind hier jeweils stärker
      auf einen Schwerpunkt fokussiert. Im Unterricht ist es allerdings häufig
      so, dass Kinder nicht nur bei einem Schwerpunkt Schwierigkeiten haben. Es
      ist deshalb wichtig über Wissen zu verschiedene Schwierigkeitsschwerpunkte
      und Fehlertypen zu verfügen. Dies bildet eine Grundlage für Diagnose- und
      Fördermaßnahmen, um in entsprechenden Unterrichtssituationen eine sicher
      reagieren zu können und handlungsfähig zu sein.
    </p>
    <p>
      Ein weiters Beispiel kann Sie beim Aufbau Ihrer diagnostischen
      Handlungsfähigkeit unterstützen.
    </p>

    <AppButtonCollapse>
      <div style="background-color: white">
        <LernbausteinStempeln
          :id="LBSTStempeln[2].id"
          :workOrder="LBSTStempeln[2].workOrder"
          :elements="LBSTStempeln[2].elements"
          :image="LBSTStempeln[2].image"
        >
          <template v-slot:alternative-content-0>
            <p>
              In dem Plustrio von Sina ist zu erkennen, dass sie in der
              Rechengeschichte die Grundvorstellung des Zusammenfassens zeigt.
              Bei dieser Grundvorstellung werden zwei Mengen zusammengelegt. Die
              Grundvorstellung des Hinzufügen, wird daher nicht von Sina
              angesprochen, dafür müsste zu einer Menge an Objekten, eine
              weitere Menge hinzugetan werden. Auch wird an dem Rechenstrich
              nicht deutlich ob Sina dort das Konstanzgesetz der Summe
              verwendet.
            </p>
          </template>
          <template v-slot:alternative-content-1>
            <p>
              Sina kann bereits Additionsaufgaben, wie beispielsweise 8+3
              richtig lösen und sie zeigt zudem, dass sie zu einer
              Additionsaufgabe eine passende Rechengeschichte verfassen kann. In
              dieser Rechengeschichte zeigt sie, die Grundvorstellung
              Zusammenfassen. Womit Sina aber noch Probleme zu haben scheint,
              ist die Darstellung einer Additionsaufgabe am Rechenstrich. Dies
              gelingt ihr in dem Plustrio, für die Aufgabe 8+3, nicht.
            </p>
            <p>
              Eine passende Diagnoseaufgabe, sollte daher untersuchen wie weit
              die Schwierigkeiten von Sina, bei der Darstellung am Rechenstrich
              gehen.
            </p>
          </template>
          <template v-slot:alternative-content>
            <p>
              In dem Plustrio von Sina ist zu erkennen, dass sie in der
              Rechengeschichte die Grundvorstellung des Zusammenfassens zeigt.
              Bei dieser Grundvorstellung werden zwei Mengen zusammengelegt. Die
              Grundvorstellung des Hinzufügen, wird daher nicht von Sina
              angesprochen, dafür müsste zu einer Menge an Objekten, eine
              weitere Menge hinzugetan werden. Auch wird an dem Rechenstrich
              nicht deutlich ob Sina dort das Konstanzgesetz der Summe
              verwendet.
            </p>
            <p>
              Sina kann bereits Additionsaufgaben, wie beispielsweise 8+3
              richtig lösen und sie zeigt zudem, dass sie zu einer
              Additionsaufgabe eine passende Rechengeschichte verfassen kann. In
              dieser Rechengeschichte zeigt sie, die Grundvorstellung
              Zusammenfassen. Womit Sina aber noch Probleme zu haben scheint,
              ist die Darstellung einer Additionsaufgabe am Rechenstrich. Dies
              gelingt ihr in dem Plustrio, für die Aufgabe 8+3, nicht.
            </p>
            <p>
              Eine passende Diagnoseaufgabe, sollte daher untersuchen wie weit
              die Schwierigkeiten von Sina, bei der Darstellung am Rechenstrich
              gehen.
            </p>
          </template>
        </LernbausteinStempeln>
      </div>
    </AppButtonCollapse>

    <p>
      Wie in den Aktivitäten festgestellt, verläuft das Erlernen des
      Einspluseins nicht immer ohne Schwierigkeiten. Die folgende Aktivität soll
      Ihnen einen Einblick in einige typische Fehlertypen geben, um Ihr
      Bewusstsein für Muster in Fehlern zu stärken.
    </p>

    <div style="background-color: white">
      <LernbausteinGruppen
        :id="LBST_Fehler_Gruppen_V2.id"
        :checkAfterHowManyItems="LBST_Fehler_Gruppen_V2.checkAfterHowManyItems"
        :checkAfterExactItemCount="
          LBST_Fehler_Gruppen_V2.checkAfterExactItemCount
        "
        :showGroupNames="LBST_Fehler_Gruppen_V2.showGroupNames"
        :workOrder="LBST_Fehler_Gruppen_V2.workOrder"
        :possibleCategories="LBST_Fehler_Gruppen_V2.possibleCategories"
        :elements="LBST_Fehler_Gruppen_V2.elements"
        :responses="LBST_Fehler_Gruppen_V2.responses"
      >
        <template v-slot:alternative-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td><strong>Zählfehler</strong></td>
                  <td><strong>Erklärung</strong></td>
                  <td><strong>Beispiel</strong></td>
                </tr>
                <tr>
                  <td>Eins-und-eins-Fehler der Nähe um -1</td>
                  <td>
                    Typischer Fehler beim Weiterzählen. Der erste Finger wird
                    bereits bei dem Zahlwort des ersten Summanden gezeigt.
                  </td>
                  <td class="no-wrap">
                    <v-row no-gutters justify="center">9+3</v-row>
                    <v-row no-gutters justify="center">"9 10 11"</v-row>
                  </td>
                </tr>
                <tr>
                  <td>Eins-und-eins-Fehler der Nähe um +1</td>
                  <td>
                    Typischer Fehler beim Weiterzählen. Der Zählprozess wird
                    richtig begonnen. Es kommt zur Unsicherheit, ob das zuletzt
                    genannte Zahlwort das korrekte Ergebnis darstellt oder ob
                    das nächste Zahlwort genannt werden muss.
                  </td>
                  <td class="no-wrap">
                    <v-row no-gutters justify="center">9+3</v-row>
                    <v-row no-gutters justify="center"
                      >"10 11 12, also 13"</v-row
                    >
                  </td>
                </tr>
                <tr>
                  <td>Auslassungen oder Doppelzählungen</td>
                  <td>
                    Vor allem beim Alleszählen (mit Material oder Fingern) kann
                    es passieren, dass Zählzahlen oder Finger ausgelassen oder
                    dass Finger doppelt gezählt werden.
                  </td>
                  <td class="no-wrap">
                    <v-row no-gutters justify="center">9+3</v-row>
                    <v-row no-gutters justify="center"
                      >"1,2,3 … 4,5,6,7,8"</v-row
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </LernbausteinGruppen>
    </div>

    <p>
      In der Aktivität gab es jeweils eine Lösung eines Kindes, welche genutzt
      wurde. Es ist mit einer Bearbeitung schwer darauf zu schließen, ob ein
      Kind einen Fehler wiederkehrend macht – also eventuell über eine
      Fehlvorstellung verfügt. Tiefere Diagnosen sind daher sinnvoll, um auf
      genauere und anhaltende Probleme schließen zu können. Durch die Aktivität
      haben Sie einen Einblick in mögliche typische Fehler beim Einspluseins
      erhalten. Die folgende Tabelle bietet einen Überblick über einige weitere
      Fehlertypen, die ebenfalls häufig beim Einspluseins auftreten können.
    </p>
    <p>
      Es kann bei den Fehlertypen in Zähl- und Rechenfehler unterteilt werden
      (vgl. Götze et al., 2019):
    </p>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td><strong>Rechenfehler</strong></td>
            <td><strong>Erklärung</strong></td>
            <td><strong>Beispiel</strong></td>
          </tr>
          <tr>
            <td>Zehnerüberschreitung nicht beachtet</td>
            <td>
              Kinder konzentrieren sich stark auf den Zehnerübergang, dabei
              vergessen sie den Zehner im Ergebnis, weil sie sich zu sehr auf
              den Einer fokussieren.
            </td>
            <td class="no-wrap">
              <v-row no-gutters justify="center">7+6 sind 3.</v-row>
            </td>
          </tr>
          <tr>
            <td>Kippfehler (falsche Richtung einer Teiloperation)</td>
            <td>
              Beim Auffüllen bis zum Zehner, wird danach die Rechenoperation
              gewechselt, d.h., der Rest wird abgezogen. Dabei wird der
              Zehnerübergang vermieden. Bei der Addition ist dieser Fehler
              seltener zu finden.
            </td>
            <td class="no-wrap">
              <v-row no-gutters justify="center">7+6=7,</v-row>
              <v-row no-gutters justify="center">weil (7+3)-3=7</v-row>
            </td>
          </tr>
          <tr>
            <td>Verwechseln der Operation</td>
            <td>
              Der zweite Summand wird subtrahiert statt addiert. Der Fehler
              tritt in der Regel nur dann auf, wenn der erste Summand größer als
              der zweite Summand ist.
            </td>
            <td class="no-wrap">
              <v-row no-gutters justify="center">7+6 sind 1.</v-row>
            </td>
          </tr>
          <tr>
            <td>Zwischenergebnisse als Lösung</td>
            <td>
              Als Endergebnis wird das Zwischenergebnis der einfacheren
              Ableitungsaufgabe genommen.
            </td>
            <td class="no-wrap">
              <v-row no-gutters justify="center">9+6 sind 16.</v-row>
              <v-row no-gutters justify="center">"Ich habe das über</v-row>
              <v-row no-gutters justify="center">10+6 gelöst."</v-row>
            </td>
          </tr>
          <tr>
            <td>Abruffehler</td>
            <td>
              Eine vermeintlich auswendig gelernte Aufgabe wurde falsch gemerkt
              oder die Lösung nicht richtig abgerufen.
            </td>
            <td class="no-wrap">
              <v-row no-gutters justify="center">"9+8 sind 18, das</v-row>
              <v-row no-gutters justify="center">habe ich mir gemerkt."</v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <br />
    <p>
      Im nachfolgenden Beispiel haben Sie nun abschließend die Gelegenheit
      zunächst eine Feindiagnostik auf Grundlage erster diagnostischer
      Erkenntnisse durchzuführen und im Anschluss daran eine geeignete
      Förderaufgabe auszuwählen.
    </p>

    <div style="background-color: white">
      <LernbausteinPfade
        :id="LBST_Pfade_Diagnose.id"
        :workOrder="LBST_Pfade_Diagnose.workOrder"
        :image="LBST_Pfade_Diagnose.image"
        :imageSizePercent="LBST_Pfade_Diagnose.imageSizePercent"
        :mediaDescription="LBST_Pfade_Diagnose.mediaDescription"
        :audio="LBST_Pfade_Diagnose.audio"
        :elements="LBST_Pfade_Diagnose.elements"
        :responses="LBST_Pfade_Diagnose.responses"
        :pupilName="LBST_Pfade_Diagnose.pupilName"
      />
    </div>
    <AppLiteraturEPE />
    <AppBottomNavEPE
      next="/einspluseins/check"
      back="/einspluseins/uebergang"
    />
  </div>
</template>

<script>
import AppBottomNavEPE from "@/common/AppBottomNavEPE";
import AppLiteraturEPE from "@/common/AppLiteraturEPE";
import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinPfade from "@/components/faledia/explorativeElemente/LernbausteinPfade";
import AppButtonCollapse from "@/common/AppButtonCollapse";

import LBST_Stempeln_Celine_V1 from "@/components/faledia/seiten/einsPlusEins/LBST/LBST_1+1_Stempeln_Celine_V1.js";
import LBST_Stempeln_Ravi_V1 from "@/components/faledia/seiten/einsPlusEins/LBST/LBST_1+1_Stempeln_Ravi_V1.js";
import LBST_Stempeln_Sina_V1 from "@/components/faledia/seiten/einsPlusEins/LBST/LBST_1+1_Stempeln_Sina_V1.js";
import LBST_Fehler_Gruppen_V2 from "@/components/faledia/seiten/einsPlusEins/LBST/LBST_1+1_Fehler_Gruppen_V2";
import LBST_Pfade_Diagnose from "@/components/faledia/seiten/einsPlusEins/LBST/LBST_1+1_Pfade_Diagnose";

export default {
  components: {
    AppBottomNavEPE,
    AppLiteraturEPE,
    LernbausteinStempeln,
    LernbausteinGruppen,
    LernbausteinPfade,
    AppButtonCollapse,
  },
  data: () => ({
    LBSTStempeln: [
      LBST_Stempeln_Celine_V1,
      LBST_Stempeln_Ravi_V1,
      LBST_Stempeln_Sina_V1,
    ],
    LBST_Fehler_Gruppen_V2,
    LBST_Pfade_Diagnose,
  }),
};
</script>

<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
</style>
