function createObject() {
  let example = {
    id: "LBST_1+1_Stempeln_Celine_V1",
    workOrder: `Links sehen Sie ein Kinderdokument. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangene Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.`,
    image: require("@/assets/epe/Stempeln/Celine.png"),
    elements: [
      [
        {
          question:
            "Welche Aussage lässt sich anhand der Dokumente von Celine belegen?",
          answers: [
            {
              correct: true,
              text:
                "Der Darstellungswechsel von der Rechenaufgabe zum Rechenstrich gelingt Celine.",
              response: `Genau! Der Darstellungswechsel von der Rechenaufgabe zum Rechenstrich gelingt Celine und sie stellt die Aufgabe somit richtig am Rechenstrich dar.`,
            },
            {
              correct: false,
              text:
                "Die Darstellung am Rechenstrich scheint Celine noch Schwierigkeiten zu bereiten.",
              response: `Das stimmt so nicht. Celine scheint keine Schwierigkeiten mit der Darstellung am Rechenstrich zu haben. Im Gegenteil sie stellt die Rechenaufgabe richtig am Rechenstrich dar.`,
            },
            {
              correct: false,
              text:
                "Bei der Bearbeitung am Rechenstrich wird die Verwendung des Konstanzgesetz sichtbar.",
              response: `Das stimmt so leider nicht. Celine scheint hier nicht das Konstanzgesetz zur Hilfe zu nehmen. Es erscheint eher so, dass sie das Assoziativgesetz zur Hilfe nimmt, indem sie zunächst zum Zehner rechnet und dann weiter.`,
            },
          ],
        },
      ],
      [
        {
          question:
            "In welchem Bereich vermuten Sie auf Grundlage der gezeigten Dokumente noch Schwierigkeiten?",
          answers: [
            {
              correct: false,
              text:
                "Das Berechnen der Aufgabe 9+3 am Rechenstrich und als Aufgabe bereitet Celine noch Probleme.",
              response: `Das ist so nicht richtig. Celine zeigt gerade bei der Berechnung am Rechenstrich und der Rechenaufgabe keine Probleme.`,
            },
            {
              correct: true,
              text:
                "Die Grundvorstellungen der Addition scheinen Celine im Rahmen von Rechengeschichten noch Probleme zu bereiten.",
              response: `Genau, das vermuten wir auch. Celine zeigt bei der Rechengeschichte keinerlei Grundvorstellungen. Sie verwendet zwar die Zahlen der Aufgabe, setzt diese aber in keinen sinnvollen Zusammenhang.`,
            },
            {
              correct: false,
              text:
                "Celine hat allgemein Probleme in allen Bereichen der Addition. Sowohl beim Darstellungswechsel, beim Ausrechnen, als auch bei den Grundvorstellungen.",
              response: `Das ist so nicht richtig. Celine zeigt sehr wohl, dass ihr der Darstellungswechsel von der Rechenaufgabe zum Rechenstrich gelingt und auch das lösen der Aufgabe gelingt Celine in diesem Zusammenhang. Sie scheint also nicht in allen Bereichen der Addition Schwierigkeiten.`,
            },
          ],
        },
        {
          question:
            "Welche Diagnoseaufgabe würden Sie dem Kind stellen, sodass Sie überprüfen können, ob der von Ihnen vermutete Fehler zutrifft?",
          answers: [
            {
              correct: false,
              text: "Rechne die Aufgabe 5+7 mit Plättchen.",
              response: `Die gewählte weiterführende Diagnoseaufgabe würden wir nicht wählen. Die Eingangsaufgaben haben Celine Fähigkeiten mit Plättchen zu rechnen nicht abgefragt und daher würde diese Aufgabe keinerlei Informationen, über die vermuteten Probleme bei Celine, geben. Bei dieser Aufgabe würde nicht direkt eine Grundvorstellung der Addition abgefragt, weshalb wir diese Aufgabe für nicht sehr geeignet empfinden.`,
            },
            {
              correct: false,
              text: "Rechne die Aufgabe 5+7 am Rechenstrich. ",
              response: `Die gewählte weiterführende Diagnoseaufgabe würden wir nicht wählen. Celine zeigt bereits in der Eingangsaufgabe, dass ihr die Berechnung und Darstellung am Rechenstrich keine Probleme zu bereiten scheint. Die vermuteten Schwierigkeiten bei den Grundvorstellungen der Addition werden hier nicht aufgegriffen.`,
            },
            {
              correct: true,
              text:
                "Welche Aufgabe passt zu der Rechengeschichte? Katja liest 3 Buchseiten. Pia liest 5 Buchseiten. Wie viele Buchseiten liest Karin?",
              response: `Die gewählte weiterführende Diagnoseaufgabe würden wir auch wählen. Wir vermuten, dass Celine Schwierigkeiten damit hat Grundvorstellungen der Addition in Rechengeschichten zu verwenden. Diese Aufgabe überprüft, ob die vermuteten Schwierigkeiten zutreffen.`,
            },
          ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();
