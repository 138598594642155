function createObject() {
    let example = {
      id: "LBST_1+1_Stempeln_Sina_V1",
      workOrder: `Links sehen Sie ein Kinderdokument. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangene Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.`,
      image: require("@/assets/epe/Stempeln/Sina.png"),
      elements: [
        [
          {
            question:
              "Welche Aussage lässt sich anhand der Dokumente von Sina belegen?",
            answers: [
              {
                correct: false,
                text:
                  "Sina zeigt in ihrer Rechengeschichte die Grundvorstellung Hinzufügen.",
                response: `Das stimmt so leider nicht. In der Rechengeschichte von Sina wird die Grundvorstellung Hinzufügen nicht sichtbar. Schau dir die Tabelle zu den Grundvorstellungen der Addition nochmal an.`,
              },
              {
                correct: true,
                text:
                  "Sina zeigt in ihrer Rechengeschichte die Grundvorstellung des Zusammenfassens.",
                response: `Genau! In der Rechengeschichte von Sina wird die Grundvorstellung des Zusammenfassens sichtbar. Dabei werden zwei Mengen zusammengelegt.`,
              },
              {
                correct: false,
                text:
                  "Bei der Bearbeitung am Rechenstrich wird die Verwendung des Konstanzgesetz sichtbar.",
                response: `Das stimmt so leider nicht. An der Bearbeitung am Rechenstrich wird nicht sichtbar, ob Sina das Konstanzgesetz der Summe verwendet hat.`,
              },
            ],
          },
        ],
        [
          {
            question:
              "In welchem Bereich vermuten Sie auf Grundlage der gezeigten Dokumente noch Schwierigkeiten?",
            answers: [
              {
                correct: true,
                text:
                  "Die Darstellung der Aufgabe 8+3 am Rechenstrich scheint Sina noch Probleme zu bereiten",
                response: `Genau, das vermuten wir auch. Sina schreibt einfach die Aufgabe so wie Sie da steht unter den Rechenstrich. Dabei scheint ihr noch nicht bewusst zu sein, dass der zweite Summand durch den Bogen am Rechenstrich dargestellt wird.`,
              },
              {
                correct: false,
                text:
                  "Die Grundvorstellungen der Addition scheinen Sina im Rahmen von Rechengeschichten noch Probleme zu bereiten.",
                response: `Das ist so nicht richtig. Sina zeigt bei der Rechengeschichte die Grundvorstellung des Zusammenfassens. Sie drückt mit ihrer Rechengeschichte aus, dass zwei Mengen zusammengelegt werden.`,
              },
              {
                correct: false,
                text:
                  "Sina hat Probleme damit Additionsaufgaben zu berechnen.",
                response: `Das ist so nicht richtig. Sina berechnet die Aufgabe 8+3 scheinbar ohne Schwierigkeiten und auch die Rechengeschichte von Sina passt zu der Aufgabe 8+3. Sina scheint in einem anderen Bereich Schwierigkeiten zu haben.`,
              },
            ],
          },
          {
            question:
              "Welche Diagnoseaufgabe würden Sie dem Kind stellen, sodass Sie überprüfen können, ob der von Ihnen vermutete Fehler zutrifft?",
            answers: [
              {
                correct: false,
                text: "Male ein Bild zu der Aufgabe 6+9.",
                response: `Diese Aufgabe würden wir nicht wählen. Diese Aufgabe ist so offen, dass die Kinder nicht unbedingt einen Rechenstrich wählen, um die Aufgabe darzustellen. Sina kann die Aufgabe also (richtig) lösen, ohne dass Sie etwas über den Fehler am Rechenstrich erfahren.`,
              },
              {
                correct: true,
                text: "Welche Darstellung passt zu der Aufgabe 6+9 am Besten?",
                image: require("@/assets/epe/Stempeln/Sina_text.png"),
                response: `Diese Aufgabe würden wir auch wählen. Sina hatte in der Eingangsaufgabe Schwierigkeiten damit die Rechenaufgabe am Rechenstrich darzustellen. Diese Aufgabe würde mehr Informationen über den vermuteten Fehler geben. Die Aufgabe kann zeigen, ob Sina hier die Darstellung nimmt, bei der die Rechenaufgabe mit in den Rechenstrich übertragen wurde oder ob sie, wenn sie eine richtige Darstellung am Rechenstrich sieht, diese erkennt und wählt.`,
              },
              {
                correct: false,
                text:
                  "Welche Aufgabe passt zu der Rechengeschichte? Katja liest 3 Buchseiten. Pia liest 5 Buchseiten. Wie viele Buchseiten liest Karin?",
                response: `Diese Aufgabe würden wir nicht wählen. Sina zeigt bereits in der Eingangsaufgabe, dass sie keine Schwierigkeiten mit Rechengeschichten zu haben und zeigt in ihrer Rechengeschichte auch Grundvorstellungen der Addition. Ihr diese Aufgabe zu geben, würde uns also keine neuen Informationen zu den vermuteten Schwierigkeiten geben.`,
              },
            ],
          },
        ],
      ],
    };
    return example;
  }
  
  export default createObject();
  