function createObject() {
  let example = {
    id: "LBST_1+1_Stempeln_Ravi_V1",
    workOrder: `Links sehen Sie ein Kinderdokument. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangene Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.`,
    image: require("@/assets/epe/Stempeln/Ravi.png"),
    elements: [
      [
        {
          question: "Welche Aussage lässt sich anhand der Dokumente von Ravi belegen?",
          answers: [
            {
              correct: true,
              text: "Ravi zeigt in seiner Rechengeschichte die Grundvorstellung Hinzufügen.",
              response: "Genau! In der Rechengeschichte von Ravi wird die Grundvorstellung Hinzufügen sichtbar. Dabei wird einer Menge von Objekten eine weitere Menge hinzugefügt.",
            },
            {
                correct: false,
                text: "Ravi zeigt in seiner Rechengeschichte die Grundvorstellung des Zusammenfassens.",
                response: "Das stimmt so leider nicht. In der Rechengeschichte von Ravi wird die Grundvorstellung Zusammenfassen nicht sichtbar. Schauen Sie sich die Tabelle zu den Grundvorstellungen der Addition nochmal an.",
            },
            {
                correct: false,
                text: "Die Rechengeschichte von Ravi zeigt keine Grundvorstellung der Addition.",
                response: "Das stimmt so leider nicht. In der Rechengeschichte von Ravi wird eine Grundvorstellung der Addition sichtbar. Schauen Sie sich die Tabelle zu den Grundvorstellungen der Addition nochmal an.",
            },
          ],
        },
      ],
      [
        {
            question: "In welchem Bereich vermuten Sie auf Grundlage der gezeigten Dokumente noch Schwierigkeiten?",
            answers: [
                {
                    correct: false,
                    text: "Die Darstellung am Rechenstrich bereitet Ravi noch Probleme.",
                    response: "Das ist so nicht richtig. Ravi kommt bei der Rechenaufgabe zu einem falschen Ergebnis und damit auch bei der Darstellung am Rechenstrich und in der Rechengeschichte. Die Darstellung am Rechenstrich ist aber sonst richtig, passt nur nicht ganz zu der Aufgabe, weil das Ergebnis nicht stimmt.",
                },
                {
                    correct: false,
                    text: "Das Verfassen einer Rechengeschichte scheint Ravi noch Schwierigkeiten zu bereiten.",
                    response: "Das ist so nicht richtig. Ravi kommt bei der Rechengeschichte zwar auf ein falsches Ergebnis, aber er zeigt dennoch die Grundvorstellung Hinzufügen in der Rechengeschichte und das generelle Verfassen einer Rechengeschichte scheint Ravi keine Probleme zu bereiten.",
                },
                {
                    correct: true,
                    text: "Ravi scheint mit der Berechnung von Additionsaufgaben Schwierigkeiten zu haben.",
                    response: "Genau, das vermuten wir auch. Ravi hat Schwierigkeiten damit die Aufgabe 7+8 richtig zu lösen. Daraus ergeben sich vermutlich auch die falschen Ergebnisse an dem Rechenstrich und bei der Rechengeschichte.",
                },
            ],
        },
        {
            question: "Welche Diagnoseaufgabe würden Sie dem Kind stellen, sodass Sie überprüfen können, ob der von Ihnen vermutete Fehler zutrifft?",
            answers: [
                {
                    correct: true,
                    text: "Rechne die Aufgabe 5+7. Erkläre wie du vorgegangen bist.",
                    response: "Diese Aufgabe würden wir auch wählen. Ravi hat in der Eingangsaufgabe Schwierigkeiten beim Berechnen der Additionsaufgabe gezeigt. Diese Aufgabe kann zeigen ob Ravis Schwierigkeiten beim Berechnen auf eine Fehlertypen zurückführen lässt. Der Zusatz sein Vorgehen zu erklären gibt dabei Einblicke in seine Rechenweise und kann zeigen wo genau seine Schwierigkeiten bei der Berechnung liegen.",
                },
                {
                    correct: false,
                    text: "Trage die Aufgabe 5+7 am Rechenstrich ein.",
                    response: "Diese Aufgabe würden wir nicht wählen. Ravi hatte weniger Probleme mit der Darstellung am Rechenstrich, sondern mehr mit der Berechnung, deshalb halten wir diese Aufgabe für nicht ganz geeignet.",
                },
                {
                    correct: false,
                    text: "Schreibe eine Rechengeschichte zu der Aufgabe 5+7.",
                    response: "Diese Aufgabe würden wir nicht wählen. Ravi zeigt bereits in der Eingangsaufgabe, dass er keine Schwierigkeiten mit dem Verfassen von Rechengeschichten hat und zeigt in seiner Rechengeschichte auch die Grundvorstellung Hinzufügen. Seine Schwierigkeiten liegen vermutlich eher bei der Berechnung der Additionsaufgabe. Deshalb wird eher eine Diagnoseaufgabe gesucht, die Auskunft über das Berechnen von Additionsaufgaben gibt.",
                },
            ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();
