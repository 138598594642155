function createObject() {
  let example = {
    id: "LBST_1+1_Pfade_Diagnose",
    workOrder: `Wählen Sie aus den vier Diagnoseaufgaben zunächst genau zwei aus. Betrachen Sie dann die Lösungen und entscheiden Sie sich für genau eine - möglichst passgenaue - Förderaufgabe.`,
    image: require("@/assets/epe/Pfade/Diagnose/01.png"),
    imageSizePercent: 60,
    mediaDescription: "Tarek bearbeitet die folgende Aufgabe:",
    pupilName: "Tarek",
    elements: [
      [
        {
          selectCount: 2,
          typeText: "Diagnoseaufgabe",
          question:
            "Wählen Sie nun <u>genau zwei</u> weitere Diagnoseaufgaben aus, zu denen Sie die Lösung sehen möchten, um die Diagnose zu vertiefen.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/epe/Pfade/Diagnose/02.png"),
              solutionImage: require("@/assets/epe/Pfade/Diagnose/03.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/epe/Pfade/Diagnose/04.png"),
              solutionImage: require("@/assets/epe/Pfade/Diagnose/05.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/epe/Pfade/Diagnose/06.png"),
              solutionImage: require("@/assets/epe/Pfade/Diagnose/07.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/epe/Pfade/Diagnose/08.png"),
              solutionImage: require("@/assets/epe/Pfade/Diagnose/09.png"),
            },
          ],
        },
      ],
      [
        {
          selectCount: 1,
          typeText: "Förderaufgabe",
          question:
            "Wählen Sie nun <u>eine</u> passende Förderaufgabe aus, die zu Ihren gewählten Diagnoseaufgaben passt.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/epe/Pfade/Diagnose/10.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/epe/Pfade/Diagnose/11.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/epe/Pfade/Diagnose/12.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/epe/Pfade/Diagnose/13.png"),
            },
          ],
        },
      ],
    ],
  };

  const DA_1 = "Diagnoseaufgabe 1 zeigt, dass Tarek die Aufgabe 6+3 richtig löst. Deshalb scheint der -1 Fehler bei ihm eher unwahrscheinlich. Er wäre sonst bei dieser Aufgabe auf das Ergebnis 8, anstatt auf das Ergebnis 9 gekommen. ";

  const DA_2 = "Diagnoseaufgabe 2 lässt erkennen, dass die Aufgabe 4+3 von Tarek noch nicht problemlos gelöst werden kann. Tarek kommt nicht auf das richtige Ergebnis, sondern auf das Ergebnis 6. Daher besteht die Vermutung, dass Tarek Schwierigkeiten bei der Ableitung aus der Kernaufgabe hat. ";

  const DA_3 = "Diagnoseaufgabe 3 zeigt, dass Tarek die Aufgabe 9+3 richtig mit dem Ergebnis 12 löst. Die Aufgabe und die Lösung von Tarek zeigen damit, dass er vermutlich eher keine Schwierigkeiten mit dem Zehnerübergang zu haben scheint. ";

  const DA_4 = "Diagnoseaufgabe 4 lässt erkennen, dass die Aufgabe 9+8 von Tarek noch nicht fehlerfrei gelöst wird. Tarek kommt auf das Ergebnis 16, anstatt 17. Das Ergebnis legt nahe, dass Tarek Schwierigkeiten bei der Ableitung aus der Kernaufgabe hat, in diesem Fall bei dem Verdoppeln. ";

  const FA_1 = "Förderaufgabe 1 versucht den -1 Fehler zu fördern und ist zudem auch zur Förderung für Fehler bei der Ableitung aus der Kernaufgabe Verdoppeln geeignet. Die Diagnoseaufgaben zeigen, dass Tareks Bearbeitungen den -1 Fehler eher nicht aufweisen. Er scheint vielmehr Probleme mit der Ableitung aus der Kernaufgabe Verdoppeln zu haben. Die Aufgabe kann aber durch die Zusatzaufgabe des Erklärens für Tarek trotzdem geeignet sein. ";

  const FA_2 = "Förderaufgabe 2 versucht die Ableitung aus Verdopplungsaufgaben zu fördern. Die Diagnoseaufgaben haben gezeigt, dass gerade hier Tareks Schwierigkeiten liegen. Die Aufgabe kann Tarek dabei helfen, inwieweit Verdopplungsaufgaben beim Lösen anderer Aufgaben helfen können. ";

  const FA_2_Star = "Förderaufgabe 2 versucht die Ableitung aus Verdopplungsaufgaben zu fördern, weil sie Tarek dabei helfen kann, inwieweit Verdopplungsaufgaben beim Lösen anderer Aufgaben helfen können. Die ausgewählten Diagnoseaufgaben haben dieses Problem allerdings nicht untersucht. ";

  const FA_3 = "Förderaufgabe 3 zeigt Tarek nur einen Rechenweg auf – nämlich zuerst zum nächsten Zehner zu rechnen und dann den Rest zu addieren. Zum einen zeigen die Diagnoseaufgaben jedoch, dass Tareks Probleme vermutlich nicht hier liegen, bzw. testen nicht auf Probleme in diesem Bereich.  Zum anderen ist diese Aufgabe eher weniger als Förderaufgabe geeignet, da hier lediglich ein Rechenweg vorgegeben wird, ohne auf Zahlbeziehungen oder das Verständnis des Kindes einzugehen. ";

  const FA_4 = "Förderaufgabe 4 soll Tarek das Ableiten aus Verdopplungsaufgaben näherbringen. Dies fördert die Sicherheit im Einspluseins und hilft Tarek dabei sicherer aus Kernaufgaben abzuleiten. ";

  const PASSUNG_SEHR_GUT = `
        Diese Förderaufgabe passt sehr gut zur Diagnose.
        <br />
        `;
    const PASSUNG_GUT = `
        Diese Förderaufgabe passt ganz gut zur Diagnose.
        <br />
        `;
    const PASSUNG_SCHLECHT = `
        Diese Förderaufgabe hätten wir nach der Diagnose eher nicht gewählt.
        <br />
        `;

    const PASSUNG_SEHR_SCHLECHT = `
        Diese Aufgabe hätten wir nicht gewählt.
        <br />
        `;

    example.responses = [
        {
            id: '112',
            text: PASSUNG_GUT + DA_1 + DA_2 + FA_1,
        },
        {
            id: '113',
            text: PASSUNG_SCHLECHT + DA_1 + DA_3 + FA_1,
        },
        {
            id: '114',
            text: PASSUNG_GUT + DA_1 + DA_4 + FA_1,
        },
        {
            id: '123',
            text: PASSUNG_SCHLECHT + DA_2 + DA_3 + FA_1,
        },
        {
            id: '124',
            text: PASSUNG_GUT + DA_2 + DA_4 +FA_1,
        },
        {
            id: '134',
            text: PASSUNG_SCHLECHT + DA_3 + DA_4 + FA_1,
        },
        {
            id: '212',
            text: PASSUNG_SEHR_GUT + DA_1 + DA_2 + FA_2,
        },
        {
            id: '213',
            text: PASSUNG_GUT + DA_1 + DA_3 + FA_2_Star,
        },
        {
            id: '214',
            text: PASSUNG_SEHR_GUT + DA_1 + DA_4 +FA_2,
        },
        {
            id: '223',
            text: PASSUNG_GUT + DA_2 + DA_3 + FA_2,
        },
        {
            id: '224',
            text: PASSUNG_SEHR_GUT + DA_2 + DA_4 + FA_2,
        },
        {
            id: '234',
            text: PASSUNG_GUT + DA_3 + DA_4 + FA_2,
        },
        {
            id: '312',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_2 + FA_3,
        },
        {
            id: '313',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_3 + FA_3,
        },
        {
            id: '314',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_3,
        },
        {
            id: '323',
            text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_3 + FA_3,
        },
        {
            id: '324',
            text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_4 + FA_3,
        },
        {
            id: '334',
            text: PASSUNG_SEHR_SCHLECHT + DA_3 + DA_4 + FA_3,
        },
        {
            id: '412',
            text: PASSUNG_GUT + DA_1 + DA_2 + FA_4,
        },
        {
            id: '413',
            text: PASSUNG_SCHLECHT + DA_1 + DA_3 + FA_4,
        },
        {
            id: '414',
            text: PASSUNG_GUT + DA_1 + DA_4 + FA_4,
        },
        {
            id: '423',
            text: PASSUNG_GUT + DA_2 + DA_3 + FA_4,
        },
        {
            id: '424',
            text: PASSUNG_SEHR_GUT + DA_2 + DA_4 + FA_4,
        },
        {
            id: '434',
            text: PASSUNG_GUT + DA_3 + DA_4 + FA_4,
        },
    ]

  return example;
}

export default createObject();
